import React, { useState, useEffect } from 'react';

const EditScoreModule = (props) => {
  const [localScores, setLocalScores] = useState([]);

  useEffect(() => {
    setLocalScores(props.score);
  }, [props.score]);

  const handleEditScore = () => {
    props.updateScore(localScores);
    props.closeEditScoreModule();
  };

  const updateScores = (input, index) => {
    let localScoresCopy = [...localScores];
    if (input.length > 0) {
      localScoresCopy[index] = parseInt(input);
    } else {
      localScoresCopy[index] = input;
    }
    setLocalScores(localScoresCopy);
  };

  const updateField = (input, index) => {
    if (input.length === 0) {
      let localScoresCopy = [...localScores];
      localScoresCopy[index] = 0;
      setLocalScores(localScoresCopy);
    }
  };

  return (
    <div
      className="edit-score-container"
      onClick={props.closeEditScoreModule}
      style={{ zIndex: 1 }}
    >
      <div
        className="edit-score-module"
        onClick={(event) => event.stopPropagation()}
        style={{ backgroundColor: `${props.color}` }}
      >
        <p className="add-player-module-title">Edit scores</p>
        <p style={{ marginTop: '0', fontStyle: 'italic' }}>
          Click on the number you want to edit
        </p>
        <div className="edit-score-line-container">
          {localScores.length > 0 ? (
            localScores.map((score, index) => (
              <div key={index} className="edit-score-line">
                <p style={{ marginTop: '2px', marginBottom: '2px' }}>
                  Round {index + 1}:
                </p>
                <input
                  className="edit-score-module-input"
                  type="number"
                  placeholder="Score.."
                  value={score}
                  style={{ backgroundColor: 'transparent' }}
                  onChange={(e) => updateScores(e.target.value, index)}
                  onBlur={(e) => updateField(e.target.value, index)}
                ></input>
              </div>
            ))
          ) : (
            <p>- No scores registered -</p>
          )}
        </div>

        <div
          className="add-player-module-button button"
          onClick={() => handleEditScore()}
          style={{ marginTop: '10px' }}
        >
          Finished
        </div>
      </div>
    </div>
  );
};

export default EditScoreModule;
