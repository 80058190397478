import React from 'react';

const YesNoModule = (props) => {
  return (
    <div
      className="add-player-container"
      onClick={props.no}
      style={{ zIndex: 1 }}
    >
      <div
        className="yes-no-module"
        onClick={(event) => event.stopPropagation()}
        style={{ backgroundColor: `${props.color}` }}
      >
        <p className="yes-no-module-title">{props.title}</p>
        <div>
          <div className="add-player-module-button button" onClick={props.yes}>
            Yes
          </div>
          <div className="add-player-module-button button" onClick={props.no}>
            No
          </div>
        </div>
      </div>
    </div>
  );
};

export default YesNoModule;
