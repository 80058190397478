import React, { useState } from 'react';

const AddPlayerModule = (props) => {
  const [name, setName] = useState('');
  const [inputOutline, setInputOutline] = useState(false);

  const handleAddPlayer = () => {
    if (name.length > 0) {
      props.addPlayer(name);
    } else {
      setInputOutline(true);
    }
  };

  return (
    <div className="add-player-container" onClick={props.closeAddPlayerModule}>
      <div
        className="add-player-module"
        onClick={(event) => event.stopPropagation()}
        style={{ backgroundColor: `${props.color}` }}
      >
        <p className="add-player-module-title">New player</p>
        <input
          className="add-player-module-input"
          style={inputOutline ? { outline: '2px solid red' } : {}}
          type="text"
          placeholder="Name.."
          value={name}
          autoFocus
          onChange={(e) => setName(e.target.value)}
        ></input>
        <div
          className="add-player-module-button button"
          onClick={() => handleAddPlayer()}
        >
          Add player
        </div>
      </div>
    </div>
  );
};

export default AddPlayerModule;
