import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import './App.css';
import logo from './images/ligretto_logo.png';
import Players from './containers/Players';

const NavBar = () => (
  <div className="navbar">
    <img src={logo} className="App-logo" alt="logo" />
  </div>
);

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <NavBar />
        <div>
          <Route exact path="/" component={Players} />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
