import React, { useState } from 'react';
import graphIcon from './../images/graph.png';
import deleteUserIcon from './../images/delete-user.png';

import YesNoModule from './../components/YesNoModule';
import EditScoreModule from './../components/EditScoreModule';

const AddScoreModule = (props) => {
  const [score, setScore] = useState('');
  const [inputOutline, setInputOutline] = useState(false);
  const [yesNoModuleOpen, setYesNoModuleOpen] = useState(false);
  const [editScoreModuleOpen, setEditScoreModuleOpen] = useState(false);

  const handleAddScore = () => {
    if (score.length > 0) {
      props.addScore(score);
    } else {
      setInputOutline(true);
    }
  };

  const openYesNoModule = () => {
    setYesNoModuleOpen(true);
  };

  const closeYesNoModule = () => {
    setYesNoModuleOpen(false);
  };

  const openEditScoreModule = () => {
    setEditScoreModuleOpen(true);
  };

  const closeEditScoreModule = () => {
    setEditScoreModuleOpen(false);
  };

  const deleteUser = () => {
    props.deleteUser();
    closeYesNoModule();
    props.closeAddScoreModule();
  };

  return (
    <div className="add-player-container" onClick={props.closeAddScoreModule}>
      <div
        className="add-score-module"
        onClick={(event) => event.stopPropagation()}
        style={{ backgroundColor: `${props.color}` }}
      >
        <p className="add-player-module-title">{props.player.playerName}</p>
        <input
          className="add-player-module-input"
          style={inputOutline ? { outline: '2px solid red' } : {}}
          type="number"
          placeholder="Score.."
          autoFocus
          value={score}
          onChange={(e) => setScore(e.target.value)}
        ></input>
        <div
          className="add-score-module-button button"
          onClick={() => handleAddScore()}
        >
          Add score
        </div>
        <div className="customize-container">
          <div
            className="delete-user-button button"
            onClick={() => openYesNoModule()}
          >
            <img src={deleteUserIcon} alt="add user" width="30" />
          </div>
          <div
            className="edit-score-button button"
            onClick={() => openEditScoreModule()}
          >
            <img
              src={graphIcon}
              alt="add user"
              width="24"
              height="30"
              style={{ marginRight: '4px', filter: 'invert(100%)' }}
            />
          </div>
        </div>
      </div>
      {yesNoModuleOpen && (
        <YesNoModule
          no={closeYesNoModule}
          yes={deleteUser}
          title={'Are you sure you want to delete the user?'}
          color={props.player.color}
        />
      )}
      {editScoreModuleOpen && (
        <EditScoreModule
          closeEditScoreModule={closeEditScoreModule}
          yes={deleteUser}
          title={'Are you sure you want to delete the user?'}
          color={props.player.color}
          score={props.player.roundScores}
          updateScore={props.updateScore}
        />
      )}
    </div>
  );
};

export default AddScoreModule;
