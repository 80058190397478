import React, { useState, useEffect, useRef } from 'react';
import PlusButton from './../components/PlusButton';
import PlayerModule from './../components/PlayerModule';
import AddPlayerModule from './../components/AddPlayerModule';
import AddScoreModule from './../components/AddScoreModule';
import YesNoModule from './../components/YesNoModule';
import SortButton from './../components/SortButton';
import ResetButton from './../components/ResetButton';

/*
const colors = {
  RED: '#A54657',
  GREEN: '#638475',
  BLUE: '#3066BE',
  PINK: '#BC8DA0',
  PURPLE: '#8A84E2',
  STEEL: '#50858B',
  VIOLET: '#B91372',
  ASPARGUS: '#8BB174',
  SPACEBLUE: '#222E50',
  HEATWAVE: '#F17300',
  BROWN: '#9A6D38',
  DARKGREEN: '#19231A',
};*/

const playerColor = [
  '#A54657',
  '#638475',
  '#3066BE',
  '#BC8DA0',
  '#8A84E2',
  '#50858B',
  '#B91372',
  '#8BB174',
  '#222E50',
  '#F17300',
  '#9A6D38',
  '#19231A',
];

const sortModes = ['high-low', 'low-high', 'default'];

const Players = () => {
  const [players, setPlayers] = useState([]);
  const [addPlayerModuleOpen, setAddPlayerModuleOpen] = useState(false);
  const [addScoreModuleOpen, setAddScoreModuleOpen] = useState(false);
  const [yesNoModuleOpen, setYesNoModuleOpen] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(0);
  const [clickedColor, setClickedColor] = useState('#A54657');
  const [sortState, setSortState] = useState(0);
  const [leastRound, setLeastRound] = useState(0);
  const bottomScrollRef = useRef(null);

  useEffect(() => {
    const localStorePlayers = JSON.parse(localStorage.getItem('players'));
    const localStoreSort = JSON.parse(localStorage.getItem('sortState'));
    if (localStorePlayers === null) {
      setPlayers([]);
    } else {
      setPlayers(localStorePlayers);
    }
    if (localStoreSort === null) {
      setSortState(0);
    } else {
      setSortState(localStoreSort > 0 ? localStoreSort : 0);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('players', JSON.stringify(players));
    setLeastRounds();
  }, [players]);

  useEffect(() => {
    localStorage.setItem('sortState', JSON.stringify(sortState));
  }, [sortState]);

  const addPlayer = (name) => {
    setPlayers((players) => [
      ...players,
      {
        playerName: name,
        totalScore: 0,
        index: players.length,
        color: playerColor[players.length % playerColor.length],
        roundScores: [],
      },
    ]);
    closeAddPlayerModule();
    bottomScrollRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const addScore = (score) => {
    let playersCopy = [...players];
    playersCopy[clickedIndex].totalScore += parseInt(score);
    playersCopy[clickedIndex].roundScores.push(parseInt(score));
    setPlayers(playersCopy);
    closeAddScoreModule();
    setLeastRounds();
  };

  const setLeastRounds = () => {
    let least = 1000;
    players.forEach((player) => {
      if (player.roundScores.length < least) {
        least = player.roundScores.length;
      }
    });
    setLeastRound(least);
  };

  const openAddPlayerModule = () => {
    setAddPlayerModuleOpen(true);
  };

  const closeAddPlayerModule = () => {
    setAddPlayerModuleOpen(false);
  };

  const openAddScoreModule = (index, color) => {
    setClickedColor(color);
    setClickedIndex(index);
    setAddScoreModuleOpen(true);
  };

  const closeAddScoreModule = () => {
    setAddScoreModuleOpen(false);
  };

  const sortPlayers = () => {
    let sortedPlayers = [];
    switch (sortModes[sortState]) {
      case 'default':
        sortedPlayers = [...players].sort(function (a, b) {
          return a.index - b.index;
        });
        break;
      case 'high-low':
        sortedPlayers = [...players].sort(function (a, b) {
          return b.totalScore - a.totalScore;
        });
        break;
      case 'low-high':
        sortedPlayers = [...players].sort(function (a, b) {
          return a.totalScore - b.totalScore;
        });
        break;
      default:
        break;
    }
    setSortState(1 + sortState > 2 ? 0 : 1 + sortState);
    setPlayers(sortedPlayers);
  };

  const openYesNoModule = () => {
    setYesNoModuleOpen(true);
  };

  const closeYesNoModule = () => {
    setYesNoModuleOpen(false);
  };

  const resetGame = () => {
    setPlayers([]);
    setSortState(0);
    closeYesNoModule();
  };

  const deleteUser = () => {
    let playersCopy = [...players];
    playersCopy.splice(clickedIndex, 1);
    setPlayers(playersCopy);
  };

  const updateScore = (score) => {
    let playersCopy = [...players];
    playersCopy[clickedIndex].totalScore = score.reduce((a, b) => a + b, 0);
    playersCopy[clickedIndex].roundScores = score;
    setPlayers(playersCopy);
  };

  return (
    <div className="players-container">
      {players &&
        players.map((player, index) => (
          <PlayerModule
            name={player.playerName}
            totalScore={player.totalScore}
            key={index}
            index={player.index}
            overlayIndex={index}
            openAddScoreModule={openAddScoreModule}
            color={player.color}
            round={player.roundScores.length}
            leastRound={leastRound}
          />
        ))}
      {players.length === 0 && (
        <div style={{ textAlign: 'center', width: '60%', margin: '0 auto' }}>
          Add a player with the button in the bottom right corner
        </div>
      )}
      {addPlayerModuleOpen && (
        <AddPlayerModule
          closeAddPlayerModule={closeAddPlayerModule}
          addPlayer={addPlayer}
          color={playerColor[players.length % playerColor.length]}
        />
      )}
      {addScoreModuleOpen && (
        <AddScoreModule
          closeAddScoreModule={closeAddScoreModule}
          addScore={addScore}
          color={clickedColor}
          player={players[clickedIndex]}
          deleteUser={deleteUser}
          updateScore={updateScore}
        />
      )}

      {yesNoModuleOpen && (
        <YesNoModule
          no={closeYesNoModule}
          yes={resetGame}
          title={'Are you sure you want to reset the game?'}
        />
      )}

      {!addPlayerModuleOpen && !addScoreModuleOpen && !yesNoModuleOpen && (
        <PlusButton openAddPlayerModule={openAddPlayerModule} />
      )}
      {!addPlayerModuleOpen && !addScoreModuleOpen && !yesNoModuleOpen && (
        <SortButton
          sortPlayers={sortPlayers}
          sortState={sortModes[sortState]}
        />
      )}
      {!addPlayerModuleOpen && !addScoreModuleOpen && !yesNoModuleOpen && (
        <ResetButton openYesNoModule={openYesNoModule} />
      )}
      <div ref={bottomScrollRef} style={{ marginBottom: 'calc(10% + 50px)' }} />
    </div>
  );
};

export default Players;
