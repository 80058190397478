import React, { useState, useEffect } from 'react';
import sortDefaultIcon from './../images/sort-default.png';
import sortLHIcon from './../images/sort-l-h.png';
import sortHLIcon from './../images/sort-h-l.png';

const SortButton = (props) => {
  const [sortIcon, setSortIcon] = useState(sortDefaultIcon);
  const [sortStyle, setSortStyle] = useState({ mr: '0px', mt: '0px' });

  useEffect(() => {
    switch (props.sortState) {
      case 'default':
        setSortIcon(sortDefaultIcon);
        setSortStyle({ mr: '0px', mt: '0px' });
        break;
      case 'high-low':
        setSortIcon(sortHLIcon);
        setSortStyle({ mr: '-3px', mt: '4px' });
        break;
      case 'low-high':
        setSortIcon(sortLHIcon);
        setSortStyle({ mr: '-2px', mt: '-3px' });
        break;
      default:
        break;
    }
  }, [props.sortState]);

  return (
    <div className="sort-players-button button" onClick={props.sortPlayers}>
      <img
        src={sortIcon}
        alt="add user"
        width="30"
        height="30"
        style={{ marginRight: sortStyle.mr, marginTop: sortStyle.mt }}
      />
    </div>
  );
};

export default SortButton;
