import React from 'react';
import roundIcon from './../images/reset.png';

const PlayerModule = (props) => {
  return (
    <div
      className={`player-module ${props.overlayIndex === 0 ? '' : 'overlay'}`}
      onClick={() => props.openAddScoreModule(props.overlayIndex, props.color)}
      style={{ backgroundColor: `${props.color}` }}
    >
      <p className="player-name">{props.name}</p>
      <div
        className={`player-round-container ${
          props.leastRound < props.round ? 'round-above' : ''
        }`}
      >
        <img
          src={roundIcon}
          alt="add user"
          width="25"
          height="25"
          className="player-round-icon"
          style={{ filter: 'invert(100%)' }}
        />
        <p>{props.round}</p>
      </div>

      <p className="player-score">{props.totalScore}</p>
    </div>
  );
};

export default PlayerModule;
