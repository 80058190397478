import React from 'react';
import resetIcon from './../images/delete.png';

const ResetButton = (props) => {
  return (
    <div className="reset-game-button button" onClick={props.openYesNoModule}>
      <img
        src={resetIcon}
        alt="add user"
        width="30"
        height="30"
        style={{ filter: 'invert(100%)' }}
      />
    </div>
  );
};

export default ResetButton;
