import React from 'react';
import addUser from './../images/add-user.png';

const PlusButton = (props) => {
  return (
    <div
      className="button add-player-button"
      onClick={props.openAddPlayerModule}
    >
      <img
        src={addUser}
        alt="add user"
        width="30"
        style={{ marginRight: '-4px' }}
      />
    </div>
  );
};

export default PlusButton;
